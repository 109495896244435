<template>
	<div class="single-view">
		<div class="tool-box">
			<h1>{{ ii('META_TAG_PARSER') }}</h1>
			<div>
				<div class="tool-input-box">
					<s-text-field
							v-model="app.query.host"
							prepend-icon="globe"
							ref="tf"
							:placeholder="ii('INPUT_URL')"
							:loading="isLoading"
							:error="error"
							@change="refreshQuery"
					></s-text-field>
				</div>

				<div>
					<table v-if="tags" class="result-tbl">
						<tr v-for="attr in tags">
							<td class="meta-prop">{{ attr.key }}</td>
							<td class="meta-val">{{ attr.value }}</td>
						</tr>
					</table>

				</div>

				<div class="page-text-content" v-html="markdownToHtml(app.texts.content)"></div>

			</div>
		</div>
	</div>
</template>


<script>
	export default {
		data() {
			return {
				isLoading: false,
				tags: null,
				error: null
			};
		},
		methods: {
			async getTags(){
				if(!this.app.query.host)
					return;

				this.isLoading = true;
				try{
					this.error = null;
					this.tags = await this.get('HTML.getMetaTags', this.app.query);
				}catch(e){
					this.tags = null;
					this.error = e.message;
				}
				this.isLoading = false;
			}
		},
		watch: {
			'$route'() {
				this.getTags();
			}
		},
		async mounted() {
			this.$refs.tf.focus();
			this.getTags();
		}
	}
</script>

<style lang="less">
	.meta-val{
		max-width: 250px;
		word-wrap: break-word;
	}
	.meta-prop{
		max-width: 200px;
		word-wrap: break-word;
	}
	.result-tbl {
		> tr, > tbody > tr {
			max-width: 450px;
			> td:nth-child(1) {
				text-transform: none !important;
			}
		}
	}
</style>